import React, {useEffect, useState} from 'react'
import { Box, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./PhoneInput.css";
import axios from "axios"
import validator from "validator";

const FormInput = ({ placeholder, icon, ...props }) => {
  const [client, setClient] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneData, setPhoneData] = useState(null);
  const [validPhoneNumber, setValidPhoneNumber] = useState(null);


  useEffect(() => {
    axios
      .get(
        "https://api.country.is"
      )
      .then(function(response) {
        // console.log("formPhone: ", response);
        if (response) setClient(response.data);
      });
  }, []);

  function handlePhoneNumber(value, data, event) {
    // console.log("handlePhoneNumber: ", value, data);
    const code = data.dialCode;
    const number = value.replace(/[^0-9]+/g, "").slice(data.dialCode.length);
    // console.log("+" + code + number);
    setPhoneNumber(value);
    setPhoneData({
      value: "+" + code + number, // phone number with countryCode and prefix +
      dialCode: data.dialCode, // countryCode without prefix +
      countryName: data.name, // "India"
      countryCode: data.countryCode // "in"
    });
    validatePhoneNumber("+" + code + number);
  }

  function validatePhoneNumber(value) {
    if (
      !validator.isMobilePhone(
        value,
        [
          "ar-AE",
          "ar-BH",
          "ar-DZ",
          "ar-EG",
          "ar-IQ",
          "ar-JO",
          "ar-KW",
          "ar-SA",
          "ar-SY",
          "ar-TN",
          "be-BY",
          "bg-BG",
          "bn-BD",
          "cs-CZ",
          "de-DE",
          "de-AT",
          "da-DK",
          "el-GR",
          "en-AU",
          "en-CA",
          "en-GB",
          "en-GG",
          "en-GH",
          "en-HK",
          "en-MO",
          "en-IE",
          "en-IN",
          "en-KE",
          "en-MT",
          "en-MU",
          "en-NG",
          "en-NZ",
          "en-RW",
          "en-SG",
          "en-UG",
          "en-US",
          "en-TZ",
          "en-ZA",
          "en-ZM",
          "en-PK",
          "es-EC",
          "es-ES",
          "es-MX",
          "es-PA",
          "es-PY",
          "es-UY",
          "et-EE",
          "fa-IR",
          "fi-FI",
          "fj-FJ",
          "fr-FR",
          "fr-GF",
          "fr-GP",
          "fr-MQ",
          "fr-RE",
          "he-IL",
          "hu-HU",
          "id-ID",
          "it-IT",
          "ja-JP",
          "kk-KZ",
          "ko-KR",
          "lt-LT",
          "ms-MY",
          "nb-NO",
          "ne-NP",
          "nl-BE",
          "nl-NL",
          "nn-NO",
          "pl-PL",
          "pt-PT",
          "pt-BR",
          "ro-RO",
          "ru-RU",
          "sl-SI",
          "sk-SK",
          "sr-RS",
          "sv-SE",
          "th-TH",
          "tr-TR",
          "uk-UA",
          "vi-VN",
          "zh-CN",
          "zh-HK",
          "zh-MO",
          "zh-TW"
        ],
        {strictMode: true}
      )
    )
      return setValidPhoneNumber(false);
    return setValidPhoneNumber(true);
  }

  return (
    <Box 
      variant='forms.field' 
      m='2' 
      sx={{
        '@media screen and (max-width: 768px)': {
          width: 'auto',
        },
      }}
    >
      {/*<Icon content={icon} size='xs' round ml='3' pl='2' />*/}
      {/*<Input placeholder="+91" style={{width: 75, padding: "0 0 0 1rem"}}/>*/}
      <PhoneInput
        inputProps={{
          id: "phone-number",
          required: true,
          enableSearch: true
        }}
        country={
          client.country ? client.country.toLowerCase() : "in"
        }
        placeholder={placeholder?.text}
        style={{padding: "0.5rem"}}
        onChange={handlePhoneNumber}
      />
      {/*<Input
        {...props}
        type={props.type.toLowerCase()}
        placeholder={placeholder?.text}
        style={{padding: "1rem 1rem 1rem 0"}}
      />*/}
    </Box>
  )
}

export default FormInput
