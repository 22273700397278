import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, css, Spinner } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import FormPhone from '@solid-ui-components/ContentForm/FormPhone'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./PhoneInput.css";
import axios from "axios"
import validator from "validator";

const styles = {
  form: {
    position: `relative`
  },
  responseOverlay: {
    position: `absolute`,
    backgroundColor: `transparent`,
    width: `full`,
    height: `105%`,
    transition: `background-color 350ms ease-in`,
    textAlign: `center`,
    zIndex: -1,
    p: 3,
    top: 0,
    left: 0,
    active: {
      zIndex: 0,
      backgroundColor: `rgba(255,255,255,0.85)`
    }
  },
  buttonsWrapper: {
    display: `inline-flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    '.button-group-button + .button-group-link': {
      flex: `100%`,
      ml: 0,
      mt: 3
    }
  }
}

const RegisterForm = ({ id, form: { action, fields, buttons } = {} }) => {
  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = id

  const [client, setClient] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneData, setPhoneData] = useState(null);
  const [validPhoneNumber, setValidPhoneNumber] = useState(null);
  const [mismatchPassword, setMismatchPassword] = useState(null)
  const [validPassword, setValidPassword] = useState(null);
  const [passwordMsg, setPasswordMsg] = useState(null);
  const [errStr, setErrStr] = useState(null)

  const errPhone = "Invalid mobile number"
  const errMismatchPassword = "Passwords doesn't match"
  // const errInvalidPassword = " Invalid password"

  useEffect(() => {
    axios
      .get(
        "https://api.country.is"
      )
      .then(function(response) {
        // console.log("RegisterForm: ", response);
        if (response) setClient(response.data);
      });
  }, []);

  // useEffect(()=>{
  //   console.log("success: ", success)
  // },[success])

  useEffect(() => {
    // console.log("useEffect: ", formValues)
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues])

  useEffect(()=>{
    validatePassword(formValues?.[formId]?.password)

    if(formValues?.[formId]?.password !== formValues?.[formId]?.confirm_password) {
      setMismatchPassword(true)
    } else {
      setMismatchPassword(false)
    }   
  },[formValues?.[formId]?.password, formValues?.[formId]?.confirm_password])

  useEffect(()=>{
    // console.log("validPhoneNumber: ", validPhoneNumber)
    checkErr()
  },[validPhoneNumber, mismatchPassword, validPassword])

  const onChange = e => {
    // console.log("Register onChange: ", e.target.name, e.target.value, formValues)
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    })  
  }

  function handlePhoneNumber(value, data, event) {
    // console.log("handlePhoneNumber: ", value, data);
    const code = data.dialCode;
    const number = value.replace(/[^0-9]+/g, "").slice(data.dialCode.length);
    // console.log("+" + code + number);
    setPhoneNumber(value);
    setPhoneData({
      value: "+" + code + number, // phone number with countryCode and prefix +
      dialCode: data.dialCode, // countryCode without prefix +
      countryName: data.name, // "India"
      countryCode: data.countryCode // "in"
    });
    validatePhoneNumber("+" + code + number);
  }

  function validatePhoneNumber(value) {
    if (
      !validator.isMobilePhone(
        value,
        [
          "ar-AE",
          "ar-BH",
          "ar-DZ",
          "ar-EG",
          "ar-IQ",
          "ar-JO",
          "ar-KW",
          "ar-SA",
          "ar-SY",
          "ar-TN",
          "be-BY",
          "bg-BG",
          "bn-BD",
          "cs-CZ",
          "de-DE",
          "de-AT",
          "da-DK",
          "el-GR",
          "en-AU",
          "en-CA",
          "en-GB",
          "en-GG",
          "en-GH",
          "en-HK",
          "en-MO",
          "en-IE",
          "en-IN",
          "en-KE",
          "en-MT",
          "en-MU",
          "en-NG",
          "en-NZ",
          "en-RW",
          "en-SG",
          "en-UG",
          "en-US",
          "en-TZ",
          "en-ZA",
          "en-ZM",
          "en-PK",
          "es-EC",
          "es-ES",
          "es-MX",
          "es-PA",
          "es-PY",
          "es-UY",
          "et-EE",
          "fa-IR",
          "fi-FI",
          "fj-FJ",
          "fr-FR",
          "fr-GF",
          "fr-GP",
          "fr-MQ",
          "fr-RE",
          "he-IL",
          "hu-HU",
          "id-ID",
          "it-IT",
          "ja-JP",
          "kk-KZ",
          "ko-KR",
          "lt-LT",
          "ms-MY",
          "nb-NO",
          "ne-NP",
          "nl-BE",
          "nl-NL",
          "nn-NO",
          "pl-PL",
          "pt-PT",
          "pt-BR",
          "ro-RO",
          "ru-RU",
          "sl-SI",
          "sk-SK",
          "sr-RS",
          "sv-SE",
          "th-TH",
          "tr-TR",
          "uk-UA",
          "vi-VN",
          "zh-CN",
          "zh-HK",
          "zh-MO",
          "zh-TW"
        ],
        {strictMode: true}
      )
    )
      return setValidPhoneNumber(false);
    return setValidPhoneNumber(true);
  }

  const validatePassword = value => {
    if (!value) return

    if (value.length < 8) {
      setValidPassword(false);
      setPasswordMsg("Please enter at least 8 characters.");
    } else if (value.length >= 8) {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})"
      );
      // var mediumRegex = new RegExp(
      //   "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      // );

      if (strongRegex.test(value)) {
        setValidPassword(true);
        setPasswordMsg("Valid password.");
        // } else if (mediumRegex.test(value)) {
        //   setValidPassword(true);
        //   setPasswordMsg("Medium password.");
      } else {
        setValidPassword(false);
        setPasswordMsg("Week password.");
      }
    }
  };

  const checkErr = () => {
    let _errStr = null;
    // console.log("checkErr: ", validPhoneNumber, mismatchPassword)
    const errPassword = passwordErr();
    
    if(validPhoneNumber !== null && errPassword !== null && !validPhoneNumber) {
      _errStr = `${errPhone} | ${errPassword}`
    } else if( validPhoneNumber !== null && !validPhoneNumber && errPassword === null) {
      _errStr = errPhone
    } else if(errPassword !== null && validPhoneNumber) {
      _errStr = errPassword
    }

    return setErrStr(_errStr);
  }

  const passwordErr = () => {
    let _errStr = null;

    if(validPassword !== null && mismatchPassword !== null && !validPassword && mismatchPassword) {
      _errStr = `${passwordMsg} | ${errMismatchPassword}`
    } else if( validPassword !== null && !validPassword && !mismatchPassword) {
      _errStr = passwordMsg
    } else if(mismatchPassword !== null && mismatchPassword && validPassword) {
      _errStr = errMismatchPassword
    }

    // console.log("passwordErr: ", validPassword, mismatchPassword, _errStr)
    
    return _errStr;
  }

  const onSubmit = e => {
    e.preventDefault();
    
    if(!validPhoneNumber || mismatchPassword || !validPassword) return

    // console.log("onSubmit: ", e.target, formValues, action)
    const values = {
      action: "register",
      data: {
        user: {
          fName: formValues?.[formId]?.fName,
          lName: formValues?.[formId]?.lName,
          // jobTitle: jobTitle,
          phoneData: phoneData
        },
        login: {
          email: formValues?.[formId]?.email,
          password: formValues?.[formId]?.password
        },
        company: {
          name: formValues?.[formId]?.company
        },
        client: client
      }
    }
    
    handleSubmit(e, { values: values, action })
  }

  return (
    <form
      css={css(styles.form)}
      onSubmit={onSubmit}
      method='POST'
      action={action}
      // demo={action ? undefined : 'demo'}
      style={{minHeight: 150}}
    >
      {success == null && <div>
      <Box variant='forms.row'>
        <Box
          variant='forms.full'
          sx={{
            display: 'flex', 
            flexDirection: 'row',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              marginBottom: 0
            },
          }}
        >
          <FormInput            
            onChange={onChange}
            name="fName"            
            id={`${formId}.fName`}
            placeholder={{"text": "First name"}}
            value={formValues?.[formId]?.fName || undefined}
            type="TEXT"
          />

          <FormInput
            onChange={onChange}
            name="lName"
            placeholder={{"text": "Last name"}}
            value={formValues?.[formId]?.lName}
          />           
        </Box>
        <Box
          variant='forms.full'
          sx={{
            display: 'flex', 
            flexDirection: 'row',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              marginBottom: 0
            },
          }}
        >
          <FormInput
            onChange={onChange}
            name="company"
            placeholder={{"text": "Company name"}}
            value={formValues?.[formId]?.company}
          />

          {/* <FormPhone
            onChange={onChange}
            name="mobile"
            placeholder={{"text": "Mobile"}}
            value={formValues?.[formId]?.mobile}
          />   */}
          <Box 
            variant='forms.field' 
            m='2' 
            sx={{
              '@media screen and (max-width: 768px)': {
                width: 'auto',
              },
            }}
          >
            <PhoneInput
              inputProps={{
                id: "phone-number",
                required: true,
                enableSearch: true
              }}
              country={
                client.country ? client.country.toLowerCase() : "in"
              }
              placeholder={"Mobile"}
              style={{padding: "0.5rem"}}
              onChange={handlePhoneNumber}
            />
          </Box>         
        </Box>
        <Box
          variant='forms.full'
          sx={{
            display: 'flex', 
            flexDirection: 'row',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              marginBottom: 0
            },
          }}
        >
          <FormInput
            onChange={onChange}
            name="email"
            type="EMAIL"
            placeholder={{"text": "Email id"}}
            value={formValues?.[formId]?.email}
          />       
        </Box>
        <Box
          variant='forms.full'
          sx={{
            display: 'flex', 
            flexDirection: 'row',
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              marginBottom: 0
            },
          }}
        >
          <FormInput
            onChange={onChange}
            name="password"
            type="PASSWORD"
            placeholder={{"text": "Password (8 characters)"}}
            value={formValues?.[formId]?.password}
          />

          <FormInput
            onChange={onChange}
            name="confirm_password"
            type="PASSWORD"
            placeholder={{"text": "Confirm password"}}
            value={formValues?.[formId]?.confirm_password}
          />           
        </Box>
        {errStr !== null && 
          <span style={{display: "flex", color: 'red', paddingLeft: 8, fontSize: 10, marginBottom: 16}}>
            {errStr}
          </span>
        }
      </Box>
      <div style={{fontSize: 12, color: "#808080", marginLeft: 8}}>
        {
          "Password must includes uppercase and lowercase letters, numbers, and symbols !@#$%^&*."
        }
      </div>
      <Box sx={{ textAlign: `center`, marginTop: 16 }}>
        <ContentButtons
          content={buttons}
          wrapperStyles={styles.buttonsWrapper}
          space={1}
        />
      </Box>
      </div>}
      <Box
        sx={styles.responseOverlay}
        css={(submitting || success) && styles.responseOverlay.active}
      >
        {submitting && (
          <Reveal effect='fadeInDown'>
            <Spinner size='64' color='alpha' />
          </Reveal>
        )}
        {success && success.state === true && (
          <Reveal effect='fadeInDown'>
            <div>
              <BiCheckCircle size='64' css={css({ color: `success` })} />
              <div style={{color: `success`}}>Sucessfully Done! Please check your email and follow the instructions to activate your account.</div>
            </div>
          </Reveal>
        )}
        {success && success.state === false && (
          <div>
            <BiErrorCircle size='64' css={css({ color: `error` })} />
            <div style={{color: `error`}}>{success.msg || `Error! retry with correct details`}</div>
          </div>
        )}
      </Box>
    </form>
  )
}

export  { RegisterForm }

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
