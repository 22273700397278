import React from 'react'
import { Box, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'

const FormInput = ({ placeholder, icon, ...props }) => {
  return <Box 
    m='2' 
    sx={{
      flexDirection: 'column',
      width: ['auto','100%'],
      '@media screen and (max-width: 768px)': {
        width: 'auto',
      },
    }}
  >
    <Box 
      variant='forms.field' 
    >
      {/*<Icon content={icon} size='xs' round ml='3' pl='2' />*/}
      <Input
        {...props}
        type={props.type ? props.type.toLowerCase() : "text"}
        placeholder={placeholder?.text}
        required={true}
      />
    </Box>
  </Box>
}
export default FormInput
