import { useState, useEffect, useCallback } from 'react'
import axios from "axios"

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()

  const handleSubmit = (e, { values, action }) => {
    e.preventDefault()
    // console.log("handleSubmit: ", values, action, typeof values, typeof values.data)
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }

  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()
    const isDemo = target.getAttribute('demo')

    // Mimicking form submission for demos
    if (isDemo) {
      setTimeout(() => {
        setSuccess(true)
        setSubmitting(false)
      }, 1500)
      return
    }

    axios
      .post(
        action || target.action,
        values
      )
      .then((response) => {
        // console.log("response: ", response)
        target.reset()
        setSuccess({state: true, msg: response})
      })
      .catch(error => {
        // console.log("err msg: ", error.response.data)
        setSuccess({state: false, msg: error.response.data || undefined})
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
    setValues(undefined)
    setAction(undefined)
  }, [])

  return {
    handleSubmit,
    submitting,
    success,
    reset
  }
}

export default useForm
