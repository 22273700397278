import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'

const Seo = ({
  title,
  name,
  description,
  excerpt,
  meta,
  keywords,
  author,
  thumbnail,
  siteUrl,
  schemaMarkup
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
  const thumbnailUrl =
    thumbnail &&
    (thumbnail.startsWith('//')
      ? thumbnail
      : siteUrl && `${siteUrl}${thumbnail}`)

  /**
   * Meta Tags
   */

  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },

    { property: 'og:title', content: title || site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name || name },
    { property: 'og:image', content: thumbnailUrl },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name || name },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter.url }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (meta) {
    metaTags.push(meta)
  }

  metaTags.push({ name: 'initial-scale', content: '1.0' })
  metaTags.push({ 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' })
  metaTags.push({ name: 'author', content: 'DotNext Technologies Pvt. Ltd.' })

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s`}
      meta={metaTags}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      // Google Trnaslate embedding to header bar ////
      <script type="text/javascript"> 
        {`function googleTranslateElementInit() { 
            new google.translate.TranslateElement(
                { pageLanguage: 'en',
                  layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL, 
                  autoDisplay: false
                }, 
                'google_translate_element'
            ); 
        } `}
    </script> 
    <script type="text/javascript" src=
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit">
    </script> 
    // Event snippet for Website traffic conversion page //
    <script>
      {/* {gtag('event', 'conversion', {'send_to': 'AW-10834344305/uRXyCPeY_Y0DEPHym64o'})} */}
    </script>
    </Helmet>
  )
}

export default Seo
